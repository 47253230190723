<template>
    <div class="container">
        <div v-for="(item, index) in replydata" :key="index">
            <el-descriptions class="margin-t-30" title="" :column="1" size="medium" border>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-user"></i>
                        留言人员
                    </template>
                    {{ item.name }}  {{ item.phone }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-time"></i>
                        留言时间
                    </template>
                    {{ item.time }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-tickets"></i>
                        留言内容
                    </template>
                    {{ item.content }}
                </el-descriptions-item>
            </el-descriptions>
            <el-descriptions class="margin-t-30" title="" :column="1" size="medium" border>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-tickets"></i> 回复内容
                    </template>
                    {{ item.handleinfo }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-time"></i> 回复时间
                    </template>
                    {{ item.updated_at }}
                </el-descriptions-item>
            </el-descriptions>
        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            replydata: [],
        }
    },
    mounted() {
        this.$nextTick(() => {
            const layui = window.layui;
            layui.use(["layer"], () => {
                var layer = layui.layer;
            });
        });
        let phone = this.$route.query.phone;
        this.getreply(phone);
    },
    methods: {
        getreply(phone) {
            this.$axios.post('web/getreply/', { phone: phone }).then(res => {
                if (res.status == 'ok') {
                    this.replydata = res.data;
                }
            }).catch(err => { })
        }
    }
}
</script>
<style>
td {
    font-size: 13px !important;
    line-height: 30px !important;
}

th {
    font-size: 13px !important;
    width: 100px;
}
</style>